<template>
  <sm-editable-list
    ref="editableList"
    :isLoading="isLoadingPage"
    :controllerName="controllerName"
    :parentName="parentName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showEditButton
    @edit="onEdit"
  >
    <template v-slot:customersListButton="{ row }">
      <sm-button
        class="customers-list-button"
        @click="onOpenCustomersForAccount(row.id)"
      >
        Связанные клиенты
      </sm-button>
    </template>
  </sm-editable-list>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
// api
import customersApi from '@/api/customers.js';

export default {
  name: 'AccountsForCustomer',

  components: {
    SmEditableList,
    SmButton,
  },

  customersApi,

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Accounts',
      parentName: 'Customer',
      tableCaption: 'Аккаунты',
      tableHeaders: [
        { text: 'ID', alias: 'id', order: 'id' },
        { text: 'ФИО', alias: 'fio', order: 'fio' },
        { text: 'Должность', alias: 'position', order: 'position' },
        { text: 'Дополнительные телефоны', alias: 'phone', order: 'phone' },
        { text: 'Email', alias: 'mail', order: 'mail' },
        { text: 'Логин (телефон)', alias: 'login', order: 'login' },
        { text: 'Пароль', alias: 'password', order: 'password' },
        { text: 'Создан', alias: 'created', order: 'created' },
        {
          text: 'Верификационный телефон',
          alias: 'verificatedPhone',
          order: 'verificatedPhone',
        },
        {
          text: 'Верифицирован',
          alias: 'isPhoneVerificated',
          order: 'isPhoneVerificated',
        },
        { text: 'Передана в пулл', alias: 'isPool', order: 'isPool' },
        {
          text: 'Контактное лицо по нашим услугам',
          alias: 'isContactPersone',
          order: 'isContactPersone',
        },
        {
          text: 'Контактное лицо по финансовым вопросам',
          alias: 'isContactFinancePersone',
          order: 'isContactFinancePersone',
        },
        {
          text: 'Архивный',
          alias: 'isArchive',
          order: 'isArchive',
        },
        {
          alias: 'customersListButton',
        },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      childList: (state) => state.editableList.childList,
    }),

    id() {
      return +this.$route.params.id;
    },

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты',
          route: { name: 'Customers' },
        },
        {
          text: `Редактирование клиента ${this.item.data?.name}`,
          route: { name: 'CustomerEdit', params: { id: this.id } },
        },
        {
          text: 'Аккаунты',
        },
      ];
    },

    isLoadingPage() {
      return this.item.isLoading || this.childList.isLoading;
    },
  },

  created() {
    this.getItem({ name: 'Customers', id: this.id })
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      getCommonList: 'common/getCommonList',
    }),

    onEdit(id) {
      this.$router.push({
        name: 'AccountForCustomerEdit',
        params: {
          id,
          parentId: this.id,
        },
      });
    },

    onOpenCustomersForAccount(id) {
      this.$router.push({ name: 'CustomersForAccount', params: { id } });
    },
  },
};
</script>

<style lang="scss">
.customers-list-button {
  width: auto;
  min-width: 103px;
  word-break: normal;
  text-align: center;
}
</style>
